import React, { useState } from 'react';
import { faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PosterImg from '../images/plakat.jpg';

const Poster = () => {
    const [ posterHidden, setPosterHidden ] = useState(false);

    return (
        <div className={`poster ${posterHidden ? 'hidden' : null }`}>
            <FontAwesomeIcon
                onClick={() => setPosterHidden((prevPosterHidden) => !prevPosterHidden)}
                className='poster__close'
                icon={faTimes}
            />
            <a href={PosterImg} target='_blank'>
                <FontAwesomeIcon
                    className='poster__view'
                    icon={faEye}
                />
            </a>
            <img className="poster__image" src={PosterImg}/>
        </div>
    )
}

export default Poster;